import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../custom.css';  // custom.css is in the src directory
import companyBrandLogo from '../images/logo.png';
import ClipLoader from 'react-spinners/ClipLoader';
import RatingScale from './RatingScale';
import { THANKYOU_MESSAGE } from '../config.js';
import { SET_TIMEOUT } from '../config.js';


const API_URL = process.env.REACT_APP_API_URL;

const HomeScreen = () => {
  const [competencies, setCompetencies] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState([]); // Responses array
  const [linkedQuestionResponses, setLinkedQuestionResponses] = useState({}); // Store linked question comments
  const [selectedScaleId, setSelectedScale] = useState(null); // Track the selected scale
  const [loading, setLoading] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [surveyId, setSurveyId] = useState('');
  const [clientId, setClientId] = useState(''); // Store client_id
  const [scheduleClientId, setScheduleClientId] = useState(''); // Store schedule_client_id
  const [scheduleParticipants, setScheduleParticipants] = useState([]); // Store schedule_participants
  const [projectId, setProjectId] = useState(''); // Store project_id
  const [clientEmail, setClientEmail] = useState(''); // Store project_id
  const [errorMessage, setErrorMessage] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('tokenid');
  const [token, setToken] = useState(null);
  const [weightage, setWeightage] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isQuestionLink, SetQuestionLink] = useState(false);
  const [scaleQuestion, SetScaleQuestion] = useState('');
  const [scaleScore, SetScaleScore] = useState('');
  const [scaleCompetency, SetScaleCompetency] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [isSurveyCompleted, SetSurveyStatus] = useState(false);
  const [clientProject, setClientProject] = useState('');

  // State to track validation error for textarea 
  const [textareaError, setTextareaError] = useState('');

  // Initialize participants state
  const [participants, setParticipants] = useState([]);
  const [ratingTitle, setRatingTitle] = useState('');

  const [clientResponse, setClientResponse] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(null);

   // State to track if we are showing all participants or just the first 3
   const [showAll, setShowAll] = useState(false);

    // Toggle to show all or limited participants
    const handleShowMore = () => {
      setShowAll(!showAll);
    };

    // Determine the participants to display based on showAll state
  const displayedParticipants = showAll ? participants : participants.slice(0, 3);



  useEffect(() => {
    if (tokenFromUrl) {
      getSurveyId(tokenFromUrl);
    }
  }, [tokenFromUrl]);

  useEffect(() => {
    if (surveyId) {
      fetchSurveyData();
    }
  }, [surveyId]);


  const fetchClientResponse = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-servey-response/${tokenFromUrl}`);
      setClientResponse(response.data["client-response"]);
    } catch (error) {
      console.error("Error fetching client response:", error);
    }
  };

  const getSurveyId = async (tokenFromUrl) => {
    try {
      const surveyResponse = await axios.get(`${API_URL}/get-survey-id/${tokenFromUrl}`);
      if (surveyResponse.data.authorized === false) {
        setIsAuthorized(false);
        setIsSubmitted(surveyResponse.data.is_submitted);
        setErrorMessage(surveyResponse.data.message);

        if(surveyResponse.data.is_submitted === true){
          fetchClientResponse();

        }

      } else {
        setIsAuthorized(true);
        console.log(surveyResponse.data,surveyResponse.data.survey_id + surveyResponse.data.fk_clients + surveyResponse.data.fk_projects + surveyResponse.data.client_email);
        setSurveyId(surveyResponse.data.survey_id);
        setClientId(surveyResponse.data.fk_clients); // Capture client_id from the response
        setScheduleClientId(surveyResponse.data.fk_schedule_client_id); // Capture client_id from the response
        setScheduleParticipants(surveyResponse.data.fk_schedule_participants); // Capture client_id from the response
        setProjectId(surveyResponse.data.fk_projects); // Capture project_id from the response
        setClientEmail(surveyResponse.data.client_email); // Capture client email from the response
        setToken(tokenFromUrl);
      }
    } catch (error) {
      console.error('Error fetching Survey ID:', error);
    }
  };

  const fetchSurveyData = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-survey/${surveyId}/${token}`);

      if (response.data.result == 'error') {

        setIsError(true);
        setErrorMessage(response.data.message);

      } else {

        setCompetencies(response.data.competencies);
        // Set the weightage (override score) for the first competency
        setWeightage(response.data.competencies[0].weightage);

        setParticipants(response.data.participants);
        setClientProject(response.data.client_project);

        console.log('participants->' + JSON.stringify(response.data.competencies[0].participants));


      }

      setLoading(false);
      console.table(response.data.competencies[0].weightage);
    } catch (error) {
      console.error("Error fetching survey data!", error);
      setLoading(false);
    }
  };

  const handleAnswer = (competencyId, score, scaleId, linkedComment = null, child_competency_id = null, weightage) => {

    console.log(isQuestionLink);

    if (isQuestionLink) {

      if (!linkedComment) {

        if (!linkedQuestionResponses[selectedScaleId]) {
          setTextareaError('Please share your feedback in the text area.');
          return false;

        }
        else {

          // If no error, reset the error message and submit the answer
          setTextareaError('');
        }
      }

      // Show thank you message after selecting the scale
      setShowThankYouMessage(true);

      // Delay to show the thank you message before moving to the next question
      setTimeout(() => {
        setShowThankYouMessage(false); // Hide thank you message
        SetQuestionLink(false);
        console.log('setTimeout-handle-answer');


      }, SET_TIMEOUT); // 1 second delay

    }

    setResponses((prevResponses) => {

      // Create new response
      const newResponse = {
        parent_competency_id: competencyId,
        score: score,
        scale_level_id: scaleId,
        weightage: weightage
      };

      if (linkedComment || child_competency_id) {
        newResponse.comment = linkedComment;
        newResponse.scale_competency_id = child_competency_id;
      }

      // Update the responses array
      const existingIndex = prevResponses.findIndex(
        (response) => response.competency_id === competencyId
      );

      let updatedResponses;

      console.log('existingIndex->' + existingIndex);

      if (existingIndex !== -1) {
        updatedResponses = [...prevResponses];
        updatedResponses[existingIndex] = newResponse;
      } else {
        updatedResponses = [...prevResponses, newResponse];
      }

      console.log("Updated Responses Array:", updatedResponses);


      // Proceed to next question or submit if it's the last question
      if (currentQuestionIndex < competencies.length - 1) {

        // If no error, reset the error message and submit the answer
        setTextareaError('');

        console.log('next question');

        setCurrentQuestionIndex(currentQuestionIndex + 1);


      } else {
        console.log('submit');
        setIsCompleted(true);
        // Use a callback to ensure we're using the latest responses
        handleSubmit(updatedResponses);
      }

      return updatedResponses; // Ensure state update
    });
  };

  const handleSubmit = async (finalResponses) => {

    SetQuestionLink(false);

    setIsLoading(true); // Set loading state to true when submission starts

    const surveyData = {
      survey_id: surveyId,
      client_id: clientId,
      schedule_client_id: scheduleClientId,
      schedule_participants: scheduleParticipants,
      project_id: projectId,
      client_email: clientEmail,
      weightage: weightage,
      token: token,
      responses: finalResponses, // Pass all the individual question responses
    };

    console.log("Final Responses Array before submitting:", finalResponses); // Debugging

    console.table(surveyData); // Survey Data

    try {
      await axios.post(`${API_URL}/surveys/response`, surveyData);
      console.log("Survey submitted successfully!");

      setSubmissionSuccess(true); // Mark success after authorization check

    } catch (error) {
      console.error("There was an error submitting the survey!", error);
    }
    finally {
      setIsLoading(false); // Set loading state back to false after submission
    }
  };


  const handleLinkedQuestionChange = (scaleId, comment) => {
    // console.log('scaleId->'+scaleId+'/comment->'+comment)  
    setLinkedQuestionResponses(prev => ({
      ...prev,
      [scaleId]: comment
    }));

    validateAnswer(comment); // Validate on input change
  };

  const handleScaleClick = (scale) => {

    setSelectedScale(scale.id); // Set the selected scale

    console.log('scale->' + JSON.stringify(scale));

    // set rating title
    setRatingTitle(scale.rating_title);


    // if this is the last question of survey then set the state true
    if ((currentQuestionIndex + 1) === competencies.length) {
      SetSurveyStatus(true);
    }

    console.log('Selected-Scale->' + JSON.stringify(scale))

    // If the scale does not have a linked question, immediately proceed with the answer
    if (scale.question_link === 0 && scale.input_field === 0) {

      console.log('handleScaleClick()-->No-Question-Linked')

      // Show thank you message after selecting the scale
      setShowThankYouMessage(true);

      console.log('1stif-scale.id->' + scale.id);

      // if survey is not completed yet then show success message and delay for 2 seconds
      if ((currentQuestionIndex + 1) !== competencies.length) {

        // Delay to show the thank you message before moving to the next question
        setTimeout(() => {
          setShowThankYouMessage(false);
          handleAnswer(currentCompetency.id, scale.value, scale.id, '', '', currentCompetency.weightage);
          SetQuestionLink(false);

          console.log('no-question-setTimeout1');

        }, SET_TIMEOUT); // 1 second delay

      } else {

        handleAnswer(currentCompetency.id, scale.value, scale.id, '', '', currentCompetency.weightage);
        SetQuestionLink(false);

      }
    }

    // if question is linked but there is no question available for specfic scale level
    if (scale.question_link === 1 && (!scale.linked_question || scale.linked_question === '')) {

      console.log('handleScaleClick()-->Question-Linked-True --> but there is no scale question available.')

      setShowThankYouMessage(true);

      if ((currentQuestionIndex + 1) !== competencies.length) {

        // Delay to show the thank you message before moving to the next question
        setTimeout(() => {
          setShowThankYouMessage(false);
          handleAnswer(currentCompetency.id, scale.value, scale.id, '', '', currentCompetency.weightage);
          SetQuestionLink(false);


        }, SET_TIMEOUT); // 1 second delay

      } else {

        handleAnswer(currentCompetency.id, scale.value, scale.id, '', '', currentCompetency.weightage);
        SetQuestionLink(false);

      }

      SetScaleQuestion('Please provide any feedback');
      SetScaleCompetency(0);

    }
    else {


      if (scale.question_link === 1 || scale.input_field === 1) {

        console.log('handleScaleClick()-->Question-Linked-True-OR-Input-Field-True.')

        SetQuestionLink(true);

        console.log('input_field->' + scale.input_field);

        if (scale.input_field === 1) {

          SetScaleQuestion(scale.input_linked_question);

        } else {

          SetScaleQuestion(scale.linked_question);
          SetScaleCompetency(scale.child_competency);
        }

      }

    }

    SetScaleScore(scale.value);
  };

  // Validation on form submission or change
  const validateAnswer = (input) => {

    if (input.length < 3) {
      setTextareaError('Answer must be at least 3 characters long.');
      return false;
    } else if (input.length > 255) {
      setTextareaError('Answer must not exceed 255 characters.');
      return false;
    }
    setTextareaError(''); // Clear error if valid
    return true;
  };

  // console.log('handleLinkedQuestionChange:'+JSON.stringify(linkedQuestionResponses));
  
  if (!isAuthorized && !isSubmitted) {
    return (
      <div>
        <header>
          <div className="logo">
            <img src={companyBrandLogo} alt="" />
          </div>
        </header>
        <div className="wrapper">
          <div className="survey">
            <div className="not-available">
              {errorMessage ? <><i className=""></i> {errorMessage} </> : <></>}
            </div>
          </div>
        </div>
      </div>);
  }


  if (!isAuthorized && isSubmitted && clientResponse) {
    return (
      <div>
        <header>
          <div className="logo">
            <img src={companyBrandLogo} alt="" />
          </div>
        </header>
        <div className="thank-you-container">

          {/* Thank You Message */}
          <div className="thank-you-message">
            <div className="icon-container">
              <i className="fa fa-check-circle check-icon" aria-hidden="true"></i>
            </div>
            <p className="message-text">
              {errorMessage ? <><i className=""></i> {errorMessage} </> : <></>}
            </p>
          </div>

          {/* Client Response */}
          <div className="response-container">
            <h3 className="response-heading">Your Response</h3>
            {clientResponse.client_feedback.map((feedback, index) => (
              <div key={index} className="feedback-card">
                <p className="question">{feedback.question}</p>
                <div
                  className="answer-box"
                  style={{ backgroundColor: feedback.color }}
                >
                  {feedback.answer}
                </div>
                {feedback.reason && (
                  <p className="reason">
                    <strong>Reason:</strong> {feedback.reason}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }


  if (isError) {
    return (
      
      <div>
        <header>
          <div className="logo">
            <img src={companyBrandLogo} alt="" />
          </div>
        </header>
        <div className="wrapper">
          <div className="survey">
            <div className="not-available">
              {errorMessage ? <><i className=""></i> {errorMessage} </> : <></>}
            </div>
          </div>
        </div>
      </div>);
  }

  const currentCompetency = competencies[currentQuestionIndex];

  if (isQuestionLink) {

    return (

      <div>
        <header>
          <div className="logo">
            <img src={companyBrandLogo} alt="" />
          </div>
        </header>

        <div className="wrapper">
          <div className="survey">
            <div className="infoText">
              <h3>We Value Your Feedback</h3>
              <p>Your experience matters to us! Please take a few moments to share your thoughts and help us improve.</p>
              <p>Your feedback allows us to better understand how we can continue to provide exceptional service.</p>
            </div>

            {participants.length > 0 && (
              <div className="participant-list">
                <h5>Feedbacks for Listed Candidates</h5>
                <p style={{ marginBottom: '15px' }}> <b>Project</b>: {clientProject}</p>

                {participants.length > 0 ? (
                  displayedParticipants.map((participant, index) => (
                    <div key={index} className="participant-item">
                      <p>{participant.name} - {participant.email}</p>
                    </div>
                  ))
                ) : (
                  <p>No participants found.</p>
                )}

                {/* Show "See More" or "See Less" based on the state */}
                {participants.length > 3 && (
                  <span onClick={handleShowMore} className="see-more-link">
                    {showAll ? 'See Less' : 'See More'}
                  </span>
                )}
              </div>
            )}

            
            {ratingTitle && (
              <div className="scale-status" style={{ backgroundColor: '#D4EDDA' }}>
                <h5>{ratingTitle}</h5>
              </div>
            )}

            <div className="linked-question-area">

              <div className="hightlightText">
                {scaleQuestion}
              </div>

              <div className="field">
                <textarea
                  maxLength="255"
                  className="form-control"
                  placeholder="Please Enter your Answer here"
                  value={linkedQuestionResponses[selectedScaleId] || ''}
                  onChange={(e) => handleLinkedQuestionChange(selectedScaleId, e.target.value)}
                  name="comment"
                  rows="7"
                  required
                />
                {/* Error message */}
                {textareaError && (
                  <span className="class-opp"
                    style={{
                      // position: 'absolute',
                      // right: '265px',   // Align with the left of the button
                      color: 'red',
                      fontSize: '14px',
                      marginTop: '12px',
                      display: 'block',
                      whiteSpace: 'nowrap', // Prevents text from wrapping
                    }}
                  >
                    {textareaError}
                  </span>
                )}
              </div>

              <div className="submitBtn" style={{ position: 'relative' }}>
                <button
                  className="btn btn-outline"
                  type="submit"
                  disabled={!!textareaError} // Disable submit if there's an error
                  onClick={() =>
                    handleAnswer(
                      currentCompetency.id,
                      scaleScore,
                      selectedScaleId,
                      linkedQuestionResponses[selectedScaleId] || null,
                      scaleCompetency || null,
                      currentCompetency.weightage
                    )
                  }
                >
                  {isSurveyCompleted ? 'Submit' : 'Save and Continue'}
                </button>

                {/* Character count display */}
                {/* <small style={{ display: 'block', marginTop: '5px', marginLeft: '5px' }}>
          {comment.length} / 255 characters
        </small> */}

              </div>

              {/* Error message
        {textareaError && (
          <span className="textarea-error">
            {textareaError}
          </span>
        )} */}

              {showThankYouMessage && (
                <div className="textarea-thanks">
                  <i className="fa-sharp fa-solid fa-circle-check" style={{ fontSize: '25px' }}></i>&nbsp;{THANKYOU_MESSAGE}
                </div>
              )}

            </div>
          </div></div></div>
    )
  }


  return (
    <div>
      <header>
        <div className="logo">
          <img src={companyBrandLogo} alt="" />
        </div>
      </header>

      <div className="wrapper">
        <div className="survey">
          <div className="infoText">
            <h3>We Value Your Feedback</h3>
            <p>Your experience matters to us! Please take a few moments to share your thoughts and help us improve.</p>
            <p>Your feedback allows us to better understand how we can continue to provide exceptional service.</p>
          </div>

          <div>
            {/* Conditionally show loader, success message, or survey question */}
            {isLoading ? (
              <div style={{ textAlign: 'center' }} className="loader-container">
                <ClipLoader color={'#123abc'} size={50} />
              </div>
            ) : submissionSuccess ? (
              <div className="thanks">
                <i className="fa-sharp fa-solid fa-circle-check"></i> Thank you for your valuable Feedback
              </div>

            ) :
              currentCompetency ? (
                <div>
                  <div key={currentCompetency.id}>

                      {participants.length > 0 && (
                        <div className="participant-list">
                          <h5>Feedbacks for Listed Candidates</h5>
                          <p style={{ marginBottom: '15px' }}> <b>Project</b>: {clientProject}</p>

                          {participants.length > 0 ? (
                            displayedParticipants.map((participant, index) => (
                              <div key={index} className="participant-item">
                                <p>{participant.name} - {participant.email}</p>
                              </div>
                            ))
                          ) : (
                            <p>No participants found.</p>
                          )}

                          {/* Show "See More" or "See Less" based on the state */}
                          {participants.length > 3 && (
                            <span onClick={handleShowMore} className="see-more-link">
                              {showAll ? 'See Less' : 'See More'}
                            </span>
                          )}
                        </div>
                      )}

                    {/* Character count display */}
                    <small style={{ display: 'block', marginTop: '0x', marginLeft: '0px', fontWeight: '600', marginBottom: '10px', marginTop: '10px' }}>
                      {currentQuestionIndex + 1} / {competencies.length}
                    </small>
                    <div className="hightlightText">
                      {currentCompetency.question}
                    </div>

                    <div className="satisfiedBtns">
                      <div className="inner">
                        {currentCompetency.scales && currentCompetency.scales.length > 0 ? (
                          currentCompetency.scales.map((scale, scaleIndex) => (
                            <div className="custom-btn-response" key={scale.id}>
                              {/* Render the scale buttons */}
                              <RatingScale
                                scaleIcon={scale.icon}
                                scale={scale}
                                scaleIndex={scaleIndex}
                                handleClick={handleScaleClick}
                                compentencyId={currentCompetency.id}
                                scaleColor={scale.color}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="not-available">
                            <i className=""></i> No scales available for this competency.
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                  {showThankYouMessage && (
                    <div className="thanks">
                      <i className="fa-sharp fa-solid fa-circle-check"></i> {THANKYOU_MESSAGE}
                    </div>
                  )}


                </div>
              ) : (
                <div style={{ textAlign: 'center' }} className="loader-container">
                  <ClipLoader color={'#123abc'} size={50} />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default HomeScreen;
