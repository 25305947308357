import React from 'react';
import HomeScreen from './components/HomeScreen'; // Adjust the path as necessary


function App() {
    return (
        <div>
            <HomeScreen />
        </div>
    );
}

export default App;
