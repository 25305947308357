import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faMeh, faSmile, faGrin, faGrinStars, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

const RatingScale = ({ scaleIcon, scale, scaleIndex, handleClick, compentencyId, scaleColor }) => {
  // Function to get the appropriate icon based on scale value
  const getIcon = (value) => {
    switch (value) {
      case 'icon-one':
        return <FontAwesomeIcon icon={faFrown} color="black" />;
      case 'icon-two':
        return <i className="fa-solid fa-thumbs-down"></i>;
      // return <FontAwesomeIcon icon={faMeh} color="black" />;
      case 'icon-three':
        return <FontAwesomeIcon icon={faSmile} color="black" />;
      case 'icon-four':
        return <i className="fa-solid fa-thumbs-up"></i>;
      // return <FontAwesomeIcon icon={faGrin} color="black" />;
      case 'icon-five':
        return <FontAwesomeIcon icon={faGrinStars} color="black" />;
      case 'icon-satisfied':
        return <FontAwesomeIcon icon={faThumbsUp} color="black" />;
      case 'icon-notSatisfied':
        return <FontAwesomeIcon icon={faThumbsDown} color="black" />;
      case 'fa-thumbs-up':
        return <i className="fa-solid fa-thumbs-up"></i>;
      case 'fa-thumbs-down':
        return <i className="fa-solid fa-thumbs-down"></i>;
      case 'fa-face-smile':
        return <i className="fa-solid fa-thumbs-up"></i>;
      default:
        return <span></span>;

      // return <i class="fa-solid fa-face-meh"></i>;
      // default:
      //   return <FontAwesomeIcon icon={faMeh} color="black" />;
    }
  };

  const getButtonClass = (value) => {
    switch (value) {
      case 'fa-thumbs-up':
        return 'btn btn-satisfy';
      case 'fa-thumbs-down':
        return 'btn btn-unsatisfy';
      case 'fa-face-smile':
        return 'btn btn-unsatisfy';
      case 'fa-one':
        return 'btn btn-one';
      case 'fa-two':
        return 'btn btn-two';
      case 'fa-three':
        return 'btn btn-three';
      case 'fa-four':
        return 'btn btn-four';
      case 'fa-five':
        return 'btn btn-five';
      case 'fa-six':
        return 'btn btn-six';
      case 'fa-seven':
        return 'btn btn-seven';
      case 'fa-eight':
        return 'btn btn-eight';
      case 'fa-nine':
        return 'btn btn-nine';
      case 'fa-ten':
        return 'btn btn-ten';
      default:
        return 'btn btn-satisfy';
    }
  };

  return (
    <button className="btn btn-satisfy" style={{ backgroundColor: scaleColor }} type="button"
      key={scaleIndex}
      onClick={() => handleClick(scale, compentencyId)} // Adjusted to pass the correct scale ID
    >
      {/* Render the icon based on the scale value */}
      {getIcon(scaleIcon)}

      {/* Display the rating title */}
      <p>{scale.rating_title}</p>
    </button>
  );
};

export default RatingScale;
